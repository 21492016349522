/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require('unorm')
const React = require("react")
const GlobalContextProvider = require("./src/context/globalContextProvider")
  .default

exports.onClientEntry = () => {
  // Without this function body the import will not be picked up.
}

exports.onInitialClientRender = () => {

}
exports.wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}